export const handleInitialHour = (e, presentialSchedule, setPresentialSchedule) => {
    e.preventDefault()

    setPresentialSchedule({...presentialSchedule, initialHour: e.target.value})
}

export const handleFinalHour = (e, presentialSchedule, setPresentialSchedule) => {
    e.preventDefault()

    setPresentialSchedule({...presentialSchedule, finalHour: e.target.value})
}

export const handleOnlineInitialHour = (e, onlineSchedule, setOnlineSchedule) => {
    e.preventDefault()

    setOnlineSchedule({...onlineSchedule, initialHour: e.target.value})
}

export const handleOnlineFinalHour = (e, onlineSchedule, setOnlineSchedule) => {
    e.preventDefault()

    setOnlineSchedule({...onlineSchedule, finalHour: e.target.value})
}

export const addSchedule = (e, isPresential, presentialSchedule, setPresentialSchedule, onlineSchedule, setOnlineSchedule, selectedDay, selectedDayOnline) => {
    e.preventDefault()

    if(isPresential && (selectedDay && presentialSchedule.initialHour && presentialSchedule.finalHour)){
      var datetime1 = new Date("2023-01-01T" + presentialSchedule.initialHour + "Z");
      var datetime2 = new Date("2023-01-01T" + presentialSchedule.finalHour + "Z");
      if(datetime1 < datetime2) {
        setPresentialSchedule({...presentialSchedule, 
          schedule: [...presentialSchedule.schedule, 
          {
            dayWeek: selectedDay, 
            initialHour: presentialSchedule.initialHour, 
            finalHour: presentialSchedule.finalHour
          }
        ]})
      }
    }else if(isPresential === false && (selectedDayOnline && onlineSchedule.initialHour && onlineSchedule.finalHour)){
      var datetime1 = new Date("2023-01-01T" + onlineSchedule.initialHour + "Z");
      var datetime2 = new Date("2023-01-01T" + onlineSchedule.finalHour + "Z");
      if(datetime1 < datetime2) {
        console.log('ENTREI')
        setOnlineSchedule({...onlineSchedule, 
          schedule: [
            ...onlineSchedule.schedule, 
            {
              dayWeek: selectedDayOnline, 
              initialHour: onlineSchedule.initialHour, 
              finalHour: onlineSchedule.finalHour
            }
          ],
          auxSchedule: [
            ...onlineSchedule.auxSchedule, 
            {
              dayWeek: selectedDayOnline, 
              initialHour: onlineSchedule.initialHour, 
              finalHour: onlineSchedule.finalHour
            }
          ]
        })
      }
    }
  }

  export const removeSchedule = (e, isPresential, key, presentialSchedule, setPresentialSchedule, onlineSchedule, setOnlineSchedule) => {
    e.preventDefault()

    if(isPresential){
      setPresentialSchedule({...presentialSchedule, 
        schedule: presentialSchedule.schedule.filter((item, index) => {return index !== key})})
    }else{
        if(onlineSchedule.schedule.length === onlineSchedule.auxSchedule.length){
            setOnlineSchedule({...onlineSchedule, 
                schedule: onlineSchedule.schedule.filter((item, index) => {return index !== key}),
                auxSchedule: onlineSchedule.auxSchedule.filter((item, index) => {return index !== key})}
            )
        }else{
            setOnlineSchedule({...onlineSchedule, 
                auxSchedule: key - presentialSchedule.schedule.length >= 0 ? onlineSchedule.auxSchedule.filter((item, index) => {return index !== (key - presentialSchedule.schedule.length)}) : onlineSchedule.auxSchedule,
                schedule: onlineSchedule.schedule.filter((item, index) => {return index !== key})}
            )
        }
    }
  }