import styled from "styled-components"

export const Main = styled.div `
    width: 100%;
    margin: ${({ theme }) => theme.spacing['2xl']} auto 4.9375rem auto;

    ol {
        width: 25rem; 
    } 

    h2 {
        font: ${({ theme }) => theme.headline.headlineSmHighlight};
    }

    p {
        font: ${({ theme }) => theme.text.textBase};
    }

    @media screen and (max-width: 1020px) {
      padding: 0 ${({ theme }) => theme.spacing.xl};
    }
`
export const BoxInputCheckBox = styled.div `
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing['2xl']};
    justify-content: flex-start;;
    margin: ${({ theme }) => theme.spacing.l} 0 ${({ theme }) => theme.spacing['2xl']} 0;

    label {
        gap: ${({ theme }) => theme.spacing.l};
        color: ${({ theme }) => theme.colors.gray.text_base};
        font-size: ${({ theme }) => theme.fontSize.fontSize.fontSize2xL};
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};

        input {
            all: unset;
            display: inline-block;
            width: ${({ theme }) => theme.spacing.l} !important;
            height: ${({ theme }) => theme.spacing.l} !important;
            border: 2px solid ${({ theme }) => theme.colors.secondary.health_green};
            border-radius: 2px;
        }

        input:checked {
            background-color: ${({ theme }) => theme.colors.secondary.health_green};
            width: ${({ theme }) => theme.spacing.l};
            height: ${({ theme }) => theme.spacing.l};
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                position: absolute;
                color: white;
                content: '✓';
            }
        } 
    }

    @media screen and (max-width: 675px) {
        label {
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXL};
        }
    }
`;

export const BoxForm = styled.div `
    .grid {
        margin-top: ${({ theme }) => theme.spacing.s};

        label {
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
            color: ${({ theme }) => theme.colors.gray.dark};
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};
        }

        input {
            height: 48px;
        }

        input::placeholder {
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase} !important;
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold} !important;
            color: ${({ theme }) => theme.colors.gray.disable} !important;
        }
    }
  
    .grid-template-areas {
        display: grid;
        gap: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l};    
        align-items: center;
        grid-template-columns: repeat(4, 1fr) repeat(1, minmax(9.1875rem, 1fr)) repeat(4, 1fr);
        margin-bottom: ${({ theme }) => theme.spacing.l};
    }

    .grid-template-columns-online {
        margin-bottom: ${({ theme }) => theme.spacing.l};
        display: grid;
        gap: ${({ theme }) => theme.spacing.l};
        width: 100%;
        justify-content: space-between;
        grid-template-columns:  repeat(5, 1fr);
    }

    .alert-dayweek-repeat {
        font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeSM};
        color: ${({ theme }) => theme.colors.gray.dark};
    }

    @media screen and (max-width: 1335px) {
        .grid-template-columns-online {
            grid-template-columns:  repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 1170px) {
        .grid-template-areas {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 675px) {
        .grid-template-areas {
            grid-template-columns: repeat(1, 1fr);
        }

        .grid-template-columns-online {
            grid-template-columns:  repeat(2, 1fr);
        }
    }
`;

export const HoursField = styled.form `
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.s};

    .grid-template-columns-5 {
      display: grid;
      gap: ${({ theme }) => theme.spacing.l};
      grid-template-columns: repeat(5, 1fr);
      align-items: end;
    }

    .span {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing['3xs']};

        label {
            gap: ${({ theme }) => theme.spacing['2xs']};
            color: ${({ theme }) => theme.colors.gray.dark};
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeSM};
            font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};

            input {
                width: 20px;
                height: 20px;     
            }
        }
    }

    @media screen and (max-width: 810px) {
        .grid-template-columns-5 {
            grid-template-columns: repeat(3, 1fr);
        }
        
        input {
            width: auto;
            height: auto;
        }
    }

    @media screen and (max-width: 675px) {
        h2 {
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeLG};
        }
    }

    @media screen and (max-width: 650px) {
        .grid-template-columns-5 {
            grid-template-columns: repeat(1, 1fr);
        }
    }
`

export const ScheduleListWrapper = styled.div `
    color: ${({ theme }) => theme.colors.gray.dark};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
    margin-top: ${({ theme }) => theme.spacing.s};

    .schedule-list-title {
        font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeLG};
        line-height: 1.6875rem;
    }
`

export const ScheduleList = styled.div `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: ${({ theme }) => theme.spacing.s} 1.875rem;
    margin-top: ${({ theme }) => theme.spacing['2xs']};

    .schedule {
        background: ${({ theme }) => theme.colors.gray.basic};
        font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
        line-height: ${({ theme }) => theme.lineHeight.lineHeight.lineHeightNormal};
        padding: ${({ theme }) => theme.spacing.xs}rem ${({ theme }) => theme.spacing.s};
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .remove-schedule {
        cursor: pointer;
    }

    @media screen and (max-width: 1380px) {
        .schedule {
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeSM};
        }
    }

    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 780px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 580px) {
        grid-template-columns: repeat(1, 1fr);

        .schedule {
            font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
        }
    }
`

export const spanGridClass = `
    display: grid;
    grid-column: span 4;

    @media screen and (max-width: 1170px) {
        grid-column: span 3;
    }

    @media screen and (max-width: 675px) {
        grid-column: span 1;
    }
`

export const halfGridClass = `
    display: grid;
    grid-column: span 2;

    @media screen and (max-width: 1170px) {
        grid-column: span 1;
    }
`

export const breakGridClass = `
    display: grid;
    grid-column: 1 / 3;

    @media screen and (max-width: 1170px) {
        grid-column: span 1;
    }
`

export const ButtonsWrapper = styled.div `
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.l};

    .back-button{
        width: 10rem;
        cursor: pointer;
        border: 2px solid ${({ theme }) => theme.colors.secondary.health_green};
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.white.main};
        color: ${({ theme }) => theme.colors.secondary.health_green};
    }

    .save-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0.625rem ${({ theme }) => theme.spacing.s};
        border: 2px solid ${({ theme }) => theme.colors.secondary.health_green};
        border-radius: 8px;
        width: 10rem;
        height: 2.9375rem;
        font-size: 1.125rem;
        font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
        background: ${({ theme }) => theme.colors.secondary.health_green};
        color: ${({ theme }) => theme.colors.white.main};
    }
`