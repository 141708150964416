import React, { useState, useCallback, useEffect } from "react"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import InputCheckbox from "../../components/profissionaisSaude/Inputs/inputCheckbox"
import { Stepper } from "../../components/profissionaisSaude/stepper"
import { Dropdown } from "../../components/profissionaisSaude/Inputs/Dropdown"
import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"
import { Online, Presencial, Repetir } from "../../utils/index"
import { statesContraction, convenio, acessibilidade, dayOfTheWeek } from "../../utils"
import { isEmptyString } from "../../functions/isEmptyString"
import Trash from "../../assets/icons/trash.svg"
import { Container } from "../../components/compartilhados/Container"
import { Main, BoxInputCheckBox, BoxForm, HoursField,ScheduleListWrapper, ScheduleList, spanGridClass, halfGridClass, breakGridClass, ButtonsWrapper } from "../../styles/saude/cadastro-dados-atendimento"
import { handleInitialHour, handleFinalHour, handleOnlineInitialHour, handleOnlineFinalHour, addSchedule, removeSchedule } from "../../helpers/saude/cadastro-dados-atendimento"

const Atendimento = () => {
  const titles = ["Dados Profissionais", "Atendimento"]
  const [step, setStep] = useState(2)
  const [presential, setPresential] = useState([])
  const [online, setOnline] = useState([])
  const [repetir, setRepetir] = useState([])
  const [emptyValue, setEmptyValue] = useState()
  const [form, setForm] = useState({
    clinica: "",
    duracao: "",
    acessibilidade: "",
    valor: "",
    endereco: "",
    numero: "",
  })

  const handleChange = (e) => {
    let newProp = form;
    newProp[e.target.value] = e.target.value;
    setForm({ ...newProp });
    let emptyValues = Object.values(form).some(obj => obj === "")
    setEmptyValue(emptyValues)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

  }

  //Estado
  const [selectedValue, setSelectedValue] = useState("")
  const [isSelectActive, setIsSelectActive] = useState(false)
  const [isError, setIsError] = useState(null)

  function handleSelectedOption(value) {
    setSelectedValue(value)
    setIsSelectActive(false)
  }

  const handleOpenSelect = useCallback((event) => {
    setIsSelectActive(!isSelectActive)
    setIsError(isEmptyString(selectedValue) ? "Campo é obrigatório" : null)
    event.preventDefault()
  }, [selectedValue, isSelectActive])

  //Acessibilidade
  const [selectedAccessibility, setSelectedAccessibility] = useState("")
  const [isSelectActiveAccessibility, setIsSelectActiveAccessibility] = useState(false)
  const [isErrorAccessibility, setIsErrorAccessibility] = useState(null)

  function handleSelectedOptionAccessibility(value) {
    setSelectedAccessibility(value)
    setIsSelectActiveAccessibility(false)
  }

  const handleOpenSelectAccessibility = useCallback((event) => {
    setIsSelectActiveAccessibility(!isSelectActiveAccessibility)
    setIsErrorAccessibility(isEmptyString(selectedAccessibility) ? "Campo é obrigatório" : null)
    event.preventDefault()
  }, [selectedAccessibility, isSelectActiveAccessibility])

  //Convênio
  const [selectedInsurance, setSelectedInsurance] = useState("")
  const [isSelectActiveInsurance, setIsSelectActiveInsurance] = useState(false)
  const [isErrorInsurance, setIsErrorInsurance] = useState(null)

  function handleSelectedOptionInsurance(value) {
    setSelectedInsurance(value)
    setIsSelectActiveInsurance(false)
  }

  const handleOpenSelectInsurance = useCallback((event) => {
    setIsSelectActiveInsurance(!isSelectActiveInsurance)
    setIsErrorInsurance(isEmptyString(selectedInsurance) ? "Campo é obrigatório" : null)
    event.preventDefault()
  }, [selectedInsurance, isSelectActiveInsurance])

  const [selectedInsuranceOnline, setSelectedInsuranceOnline] = useState("")
  const [isSelectActiveInsuranceOnline, setIsSelectActiveInsuranceOnline] = useState(false)
  const [isErrorInsuranceOnline, setIsErrorInsuranceOnline] = useState(null)

  function handleSelectedOptionInsuranceOnline(value) {
    setSelectedInsuranceOnline(value)
    setIsSelectActiveInsuranceOnline(false)
  }

  const handleOpenSelectInsuranceOnline = useCallback((event) => {
    setIsSelectActiveInsuranceOnline(!isSelectActiveInsuranceOnline)
    setIsErrorInsuranceOnline(isEmptyString(selectedInsuranceOnline) ? "Campo é obrigatório" : null)
    event.preventDefault()
  }, [selectedInsuranceOnline, isSelectActiveInsuranceOnline])

  //Dia da semana
  const [selectedDay, setSelectedDay] = useState("")
  const [isSelectActiveDay, setIsSelectActiveDay] = useState(false)
  const [isErrorDay, setIsErrorDay] = useState(null)

  function handleSelectedOptionDay(value) {
    setSelectedDay(value)
    setIsSelectActiveDay(false)
  }

  const handleOpenSelectDay = useCallback((event) => {
    setIsSelectActiveDay(!isSelectActiveDay)
    setIsErrorDay(isEmptyString(selectedDay) ? "Campo é obrigatório" : null)
    event.preventDefault()
  }, [selectedDay, isSelectActiveDay])

  const [selectedDayOnline, setSelectedDayOnline] = useState("")
  const [isSelectActiveDayOnline, setIsSelectActiveDayOnline] = useState(false)
  const [isErrorDayOnline, setIsErrorDayOnline] = useState(null)

  function handleSelectedOptionDayOnline(value) {
    setSelectedDayOnline(value)
    setIsSelectActiveDayOnline(false)
  }

  const handleOpenSelectDayOnline = useCallback((event) => {
    setIsSelectActiveDayOnline(!isSelectActiveDayOnline)
    setIsErrorDayOnline(isEmptyString(selectedDayOnline) ? "Campo é obrigatório" : null)
    event.preventDefault()
  }, [selectedDayOnline, isSelectActiveDayOnline])

  //Horário
  const [presentialSchedule, setPresentialSchedule] = useState({
    schedule: [],
    initialHour: "",
    finalHour: ""
  })

  const [onlineSchedule, setOnlineSchedule] = useState({
    schedule: [],
    auxSchedule: [],
    initialHour: "",
    finalHour: "", 
  })

  useEffect(() => {
    if(repetir.length > 0){
      setOnlineSchedule({...onlineSchedule, 
        schedule: [...presentialSchedule.schedule, ...onlineSchedule.schedule]})
    }else{
      setOnlineSchedule({...onlineSchedule, 
        schedule: [...onlineSchedule.auxSchedule]})
    }
  }, [repetir])

  return (
    <Container>
      <Header />
      <Main>
        <Stepper
          steps={titles}
          currentStepNumber={step}
        />
        <BoxInputCheckBox>
          <BoxForm>
            <InputCheckbox
              currentValues={presential}
              options={Presencial}
              setValue={setPresential}
            />
            {presential.length > 0 &&
              <form onSubmit={(e) => { handleSubmit(e) }} className="grid">
                <div className="grid-template-areas">
                  <TextInput
                    className="large-grid"
                    containerClass={spanGridClass}
                    type="text"
                    placeholder="Informe o nome do seu consultório"
                    label="Nome da clínica "
                    onChange={(e) => handleChange(e)}
                  />
                  <Dropdown
                    data={acessibilidade}
                    selectedValue={selectedAccessibility}
                    onSelectOption={handleSelectedOptionAccessibility}
                    isSelectActive={isSelectActiveAccessibility}
                    onOpenSelect={handleOpenSelectAccessibility}
                    isError={isErrorAccessibility}
                    onChange={(e) => handleChange(e)}
                  />
                  <TextInput placeholder="Em minutos" type="text" label="Duração da consulta" onChange={(e) => handleChange(e)} containerClass={halfGridClass}/>
                  <TextInput placeholder="R$" type="text" label="Valor da Consulta Popular Lacrei" onChange={(e) => handleChange(e)} containerClass={halfGridClass}/>
                  <TextInput type="text" containerClass={spanGridClass} label="Endereço" onChange={(e) => handleChange(e)} />
                  <TextInput type="text" label="Número" onChange={(e) => handleChange(e)} />
                  <TextInput type="text" label="Complemento" containerClass={halfGridClass}/>
                  <TextInput type="text" placeholder="Apenas números" label="CEP" containerClass={halfGridClass}/>
                  <TextInput type="text" containerClass={spanGridClass} label="Cidade" />
                  <Dropdown

                    data={statesContraction}
                    selectedValue={selectedValue}
                    onSelectOption={handleSelectedOption}
                    isSelectActive={isSelectActive}
                    onOpenSelect={handleOpenSelect}
                    isError={isError}
                  />
                  <TextInput type="text" label="Bairro" containerClass={halfGridClass}/>
                  <TextInput type="text" containerClass={breakGridClass} placeholder="Apenas números" label="Telefone" />
                  <TextInput type="text" placeholder="Apenas números" label="WhatsApp" containerClass={halfGridClass}/>
                  <Dropdown
                    data={convenio}
                    selectedValue={selectedInsurance}
                    onSelectOption={handleSelectedOptionInsurance}
                    isSelectActive={isSelectActiveInsurance}
                    onOpenSelect={handleOpenSelectInsurance}
                    isError={isErrorInsurance}
                  />
                </div>
                <HoursField>
                  <h2>Cadastro de horários de atendimento presencial</h2>
                  <div className="grid-template-columns-5">
                    <Dropdown
                      data={dayOfTheWeek}
                      selectedValue={selectedDay}
                      onSelectOption={handleSelectedOptionDay}
                      isSelectActive={isSelectActiveDay}
                      onOpenSelect={handleOpenSelectDay}
                      isError={isErrorDay}
                    />
                    <TextInput type="time" name="Hinicial" label="Hora inicial" required onChange={(e) => handleInitialHour(e, presentialSchedule, setPresentialSchedule)}/>
                    <TextInput type="time" name="HFinal" label="Hora final" required onChange={(e) => handleFinalHour(e, presentialSchedule, setPresentialSchedule)}/>
                    <Button
                      title="Adicionar"
                      onClick={(e) => addSchedule(e, true, presentialSchedule, setPresentialSchedule, onlineSchedule, setOnlineSchedule, selectedDay, selectedDayOnline)}
                      noLink
                    />
                  </div>
                  <span className="alert-dayweek-repeat">Você pode repetir os dias da semana de acordo com os seus intervalos</span>
                  {presentialSchedule.schedule.length > 0 && <ScheduleListWrapper>
                    <h2 className="schedule-list-title">Horários Cadastrados</h2>
                    <ScheduleList>
                        {presentialSchedule.schedule.map((item, key) => (
                          <div key={key} className="schedule">
                              <span>{item.dayWeek}</span>
                              <span>{item.initialHour} - {item.finalHour}</span>
                              <Trash 
                                className="remove-schedule" 
                                onClick={(e) => {removeSchedule(e, true, key, presentialSchedule, setPresentialSchedule, onlineSchedule, setOnlineSchedule)}}
                              />
                          </div>
                        ))}
                    </ScheduleList>
                  </ScheduleListWrapper>}
                </HoursField>
              </form>
            }
          </BoxForm>
          <BoxForm>
            <InputCheckbox
              currentValues={online}
              options={Online}
              setValue={setOnline}
            />
            {online.length > 0 &&
              <form className="grid">
                <div className="grid-template-columns-online">
                  <TextInput type="text" placeholder="Apenas números" label="Telefone " />
                  <TextInput type="text" placeholder="Apenas números" label="WhatsApp" />
                  <Dropdown
                    data={convenio}
                    selectedValue={selectedInsuranceOnline}
                    onSelectOption={handleSelectedOptionInsuranceOnline}
                    isSelectActive={isSelectActiveInsuranceOnline}
                    onOpenSelect={handleOpenSelectInsuranceOnline}
                    isError={isErrorInsuranceOnline}
                  />
                  <TextInput placeholder="Em minutos" type="text" className="duracao" label="Duração da consulta" />
                  <TextInput placeholder="R$" type="text" className="valor" label="Valor da Consulta Popular Lacrei" />
                </div>
                <HoursField>
                  <h2>Cadastro de horários de atendimento online</h2>
                  <div className="grid-template-columns-5">
                    <Dropdown
                      data={dayOfTheWeek}
                      selectedValue={selectedDayOnline}
                      onSelectOption={handleSelectedOptionDayOnline}
                      isSelectActive={isSelectActiveDayOnline}
                      onOpenSelect={handleOpenSelectDayOnline}
                      isError={isErrorDayOnline}
                    />
                    <TextInput type="time" name="Hinicial" label="Hora inicial" required onChange={(e) => handleOnlineInitialHour(e, onlineSchedule, setOnlineSchedule)}/>
                    <TextInput type="time" name="HFinal" label="Hora final" required onChange={(e) => handleOnlineFinalHour(e, onlineSchedule, setOnlineSchedule)}/>
                    <Button
                      title="Adicionar"
                      onClick={(e) => addSchedule(e, false, presentialSchedule, setPresentialSchedule, onlineSchedule, setOnlineSchedule, selectedDay, selectedDayOnline)}
                      noLink
                    />
                  </div>
                  <div className="span">
                    <span className="alert-dayweek-repeat">Você pode repetir os dias da semana de acordo com os seus intervalos</span>
                    <InputCheckbox
                      currentValues={repetir}
                      options={Repetir}
                      setValue={setRepetir}
                    />
                  </div>
                  {onlineSchedule.schedule.length > 0 && 
                  <ScheduleListWrapper>
                    <h2 className="schedule-list-title">Horários Cadastrados</h2>
                    <ScheduleList>
                        {onlineSchedule.schedule.map((item, key) => (
                          <div key={key} className="schedule">
                              <span>{item.dayWeek}</span>
                              <span>{item.initialHour} - {item.finalHour}</span>
                              <Trash 
                                className="remove-schedule" 
                                onClick={(e) => {removeSchedule(e, false, key, presentialSchedule, setPresentialSchedule, onlineSchedule, setOnlineSchedule)}}
                              />
                          </div>
                        ))}
                    </ScheduleList>
                  </ScheduleListWrapper>}
                </HoursField>
              </form>
            }
          </BoxForm>
        </BoxInputCheckBox>
        <ButtonsWrapper>
          <Button
            link="/saude/cadastro-dados-pessoais/"
            variant="back-button"
            maxWidth="11.875"
            linkWidth="auto"
            title="Voltar"
            shadowy
          />
          <Button
            link="/saude/cadastro-atendimento-finalizado/"
            maxWidth="11.875"
            linkWidth="auto"
            title="Confirmar"
            disabled={!emptyValue}
          />
        </ButtonsWrapper>
      </Main>
      <Footer hasButtonUp />
    </Container>
  )
}

export default Atendimento
